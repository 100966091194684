import localforage from "localforage";
import { formatCurrent } from "../../../../theme/helpers";
import { popupMessage } from "../../../modules/messages";

const printLogo = (imageDataBase64: any, printer: any) => {
  // Thêm hình ảnh vào lệnh in
  // let bitmapImg = base64ToBitmap(imageDataBase64)
  // console.log('bitmapImg',bitmapImg)

  // printer.printStoredImage('logo'); 
  

  // Đảm bảo lắng nghe phản hồi từ máy in để debug
  printer.onreceive = (res: any) => {
    console.log('res',res)
    if (res.success) {
      console.log("In thành công");
    } else {
      console.error("Lỗi khi in:", res.error);
    }
  };
};
function base64ToBitmap(base64: any) {
  const byteString = atob(base64.split(",")[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return arrayBuffer; // Trả về dữ liệu bitmap
}
function convertToGrayscale(ctx: any, canvas: any) {
  const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imgData.data;

  for (let i = 0; i < data.length; i += 4) {
    const avg = (data[i] + data[i + 1] + data[i + 2]) / 3; // Trung bình RGB
    data[i] = data[i + 1] = data[i + 2] = avg; // Áp dụng giá trị grayscale
  }

  ctx.putImageData(imgData, 0, 0);
}
function splitString(input: string, length: number) {
  const result = [];
  for (let i = 0; i < input.length; i += length) {
    result.push(input.slice(i, i + length));
  }
  return result;
}
const printRenderSyntax = (string: any, printer: any) => {
  let param = string.slice(1,2) as any
  let arr = {
    B: printer.addTextStyle(false, false, true, undefined),//bold,
    N: printer.addTextStyle(false, false, false, undefined),//Nomal,
    L: printer.addTextAlign(printer.ALIGN_LEFT),//left,
    R: printer.addTextAlign(printer.ALIGN_RIGHT),//right,
    C: printer.addTextAlign(printer.ALIGN_CENTER),//center,
    // W: printer.addTextDouble(true,true),//double size
    
  } as any
  
  if(param == 'L') {
    printer.addTextAlign(printer.ALIGN_LEFT)
    return
  }
  if(param == 'R') {
    printer.addTextAlign(printer.ALIGN_RIGHT)
    return
  }
  if(param == 'W') {
    printer.addTextDouble(true,true)
    return
  }
  return arr[param]?arr[param]:''
}
const formatFromTextData = (data: any, printer: any) => {
  let arrLine = data.split('\n')//splitString(data,42)
  // console.log('arrLine',arrLine)
  arrLine.forEach((item: any) => {
    let tag = item.slice(0,3)
    let endTag = item.slice(item.length - 4)
    printer.addTextSize(1,1)// font normal

    printRenderSyntax(tag,printer)
    let text = item.slice(3,item.length - 4)
    printer.addText(text+'\n');
    //reset
    printer.addTextStyle(false, false, false, printer.COLOR_1) 
    printer.addTextAlign(printer.ALIGN_LEFT)
    printer.addTextSize(1,1)


  })
  
  
}
export const orderPrintEpson = async(printer: any, ePosDev: any, data: any) => {
  let infoSetupPrint = await localforage.getItem("merchantsData")||{} as any
  let {receiptsetups} = infoSetupPrint||{}
  if (!printer) {
    popupMessage({icon:'info',title: 'Failed!!', description: 'Failed to connect to printer'});
    return;
  }

  printer.addLayout(printer.LAYOUT_RECEIPT, 576, 0, 0, 0, 10, 10);
  // printer.addTextAlign(printer.ALIGN_RIGHT);
  printer.addTextSmooth(true);
  // printer.addText('\n');
  if(receiptsetups?.logo) {
    // printer.drawImage(receiptsetups?.logo);
    // Chuẩn bị ảnh logo (từ một thẻ HTML Canvas hoặc Base64)
    const canvas = document.createElement("canvas");
    canvas.setAttribute("id", "canvas");
    // document.body.appendChild(canvas);
    const ctx = canvas.getContext('2d', { willReadFrequently: true }) as any; //canvas.getContext("2d") as any

    const img = new Image();
    img.crossOrigin = 'anonymous'; 
    img.src = receiptsetups?.logo; // Đường dẫn đến logo

      // Gửi lệnh in
      // printer.send();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      convertToGrayscale(ctx,canvas)
      ctx.drawImage(img, 0, 0);
      
      // Chèn logo vào lệnh in
      // Tính toán vị trí để căn giữa
      const imageWidth = 184;
      printer.addTextAlign(printer.ALIGN_CENTER);
      printer.addImage(ctx,0 , 0, imageWidth, canvas.height, printer.COLOR_1, printer.MODE_MONO);
      printReceipt(printer,data,receiptsetups)

    }
  }
  else {
    printReceipt(printer,data,receiptsetups)

  }
  // printer.addTextSize(2,2)
  // printer.addTextStyle(false, false, true, undefined);//bold
  // printer.addTextLineSpace(50)
  // printer.addText(data.merchantname + '\n');
  
};
const findDiscountLines = (discountlines: any,order: any) => {
  return discountlines.filter((i: any) => +i.DiscountAmount !=0 && i.ItemNo == order.itemno && i.LineNoExt == order.lineno)
}
const printReceipt = (printer: any, data: any,receiptsetups: any) => {
  let TransactionPaymentEntries = data.TransactionPaymentEntries||[]

  printer.addTextSize(1,1)
  printer.addTextLineSpace(32)

  if(receiptsetups?.header) {
    formatFromTextData(receiptsetups?.header,printer)
  }

  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addTextAlign(printer.ALIGN_LEFT);

  printer.addTextDouble(false, false);
  // printer.addText(`Địa chỉ: ` + data.merchantname+ '\n');
  // printer.addText('\n');

  printer.addTextAlign(printer.ALIGN_CENTER);
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addTextSize(2,2)

  printer.addTextLineSpace(70)
  let titleText = 'Phiếu Thanh Toán'
  if(data.type == 3) {
    titleText = 'Hóa Đơn'
  }
  printer.addText(titleText + '\n');
  printer.addTextLineSpace(30)

  printer.addTextSize(1,1)
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Ngày: ');
  printer.addTextStyle(false, false, false, undefined);//text normal

  let date = new Date(data.modifiedat).toLocaleString('en-GB', { hour12: false })
  printer.addText(date + '\n');

  printer.addTextAlign(printer.ALIGN_LEFT);
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Số bill: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.receiptno?.padEnd(20,' ') + '\t');
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Số GD: ');
  // formatTextLeftRight(printer,'Tổng cộng',formatCurrent(data.grossamount),true)

  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.code + '\n');
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Thu ngân: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.staffreceiptname.padEnd(20,' ') + '\t');
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Bàn số: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.tableno + '\n');
  printer.addTextStyle(false, false, true, undefined);//bold
  if(data.einvoice && data.type == 3) {
    printer.addText(`Ký hiệu: `);
    printer.addTextStyle(false, false, false, undefined);//text normal

    printer.addText( data.einvoice?.SINeInvoiceTemplateNo.padEnd(20,' ')+ '\t');
    printer.addTextStyle(false, false, true, undefined);//bold

    printer.addText(`Số HĐ: `);
    printer.addTextStyle(false, false, false, undefined);//text normal

    printer.addText( data.einvoice?.SINeInvoiceNo+ '\n');

    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText(`Mã CQT: `);
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText( data.einvoice?.SINeInvoiceCode+ '\n');

    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText('Khách hàng: ');
    printer.addTextStyle(false, false, false, undefined);//text normal
    let nameCustomer = `Khách lẻ không lấy hóa đơn`
    if(data.einvoice?.SINBuyerCompanyName) {
      nameCustomer = data.einvoice?.SINBuyerCompanyName
    }
    printer.addText(nameCustomer);
    printer.addText('\n');

    
  }
  
  
  
 

  // printer.addTextStyle(false, false, true, undefined);//bold
  // printer.addText('SĐT: ');
  // printer.addTextStyle(false, false, false, undefined);//text normal
  // printer.addText('không có' + '\n');
  // printer.addTextAlign(printer.ALIGN_LEFT);
  // Bảng tiêu đề
  printer.addText("+-----------------+----+---------+------------+\n");
  printer.addText("| ");
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText("Tên món         ");
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText("| ");
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText("SL ");
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText("| ");

  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText("Đơn giá ");
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText("| ");
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText("Thành tiền ");
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText("|\n");

  // printer.addText("| Tên món         | SL | Đơn giá | Thành tiền |\n");
  printer.addText("+-----------------+----+---------+------------+\n");
  
  let items  = data?.HospTransactionLines||data.HospTransactionLines||[]
  let totalGrossAmount = 0
  items.forEach((item: any,index: number) => {
    let lineDiscount = findDiscountLines(data.discountlines, item)
    let itemname = item.itemname
    if(item.discountamount && lineDiscount.length != 0) {
      let discount = lineDiscount.map((i: any) => i.Description).join(', ')
      itemname += `(${discount})` 
    }
    const itemLines = splitItemName(`${itemname}`, 15);
    let valueLine = item.price * item.quantity
    totalGrossAmount += valueLine
    itemLines.forEach((line: any, index: number) => {
      if (index === 0) {
        printer.addText(formatItemLine(line, item.quantity.toString(), formatCurrent(item.price), formatCurrent(valueLine)));
      } else {
        let value = ''
        if(lineDiscount[index - 1] && lineDiscount[index - 1].DiscountAmount) {
          value = `-${formatCurrent(lineDiscount[index - 1].DiscountAmount)}`
        }
        printer.addText(formatItemLine(line, '', '',value));
      }
    });
    // if(item.discountamount) {
    //   .forEach((discount: any,ind: number) =>{
    //     formatTextLeftRight(printer,discount.Description,formatCurrent(discount.DiscountAmount))
    //   })
    // }
    printer.addText("+-----------------+----+---------+------------+\n");
    if(item.customizations && item.customizations.length != 0) {
      let toppings = item.customizations
      toppings.forEach((i: any,index: number) => {
        const itemLinesTopping = splitItemName(`${i.description}`, 15);
        let valueLineCus = i.price * i.quantity
        totalGrossAmount += valueLineCus
        itemLinesTopping.forEach((line: any, index: number) => {
          if (index === 0) {
            printer.addText(formatItemLine(line, i.quantity.toString(), formatCurrent(i.price), formatCurrent(i.amount)));
          } else {
            printer.addText(formatItemLine(line, '', '',''));
          }
        });
        printer.addText("+-----------------+----+---------+------------+\n");
      });
    }
    
    else {
        // printer.addText("+-----------------+----+---------+------------+\n");
    }
  });
  
  formatTextLeftRight(printer,'Tổng cộng',formatCurrent(totalGrossAmount),true)
  formatTextLeftRight(printer,'Chiết khấu/ Giảm giá',formatCurrent(data.discountamount),true)
  printer.addTextSize(2,2)
  printer.addTextLineSpace(70)
  let total = formatCurrent(+data.grossamount)
  printer.addText((`Thanh toán`).padEnd(24 - total.length ,' ')+total + '\n');

  printer.addTextSize(1,1)
  printer.addTextLineSpace(30)

  if(TransactionPaymentEntries && TransactionPaymentEntries.length != 0) {
    printer.addText("-----------------------------------------------\n");
    let amounttendered = TransactionPaymentEntries.filter((i: any)=> !i.ischangeline).reduce(function (acc: any, obj: any) { return acc + Math.abs(+obj.amounttendered); }, 0)
    formatTextLeftRight(printer,'Tiền khách đưa',formatCurrent(+amounttendered), true)
    
    TransactionPaymentEntries.forEach((item: any) => {
      if(!item.ischangeline) {
        let textL = item.tendertypename
        let textR = formatCurrent(item.amounttendered)
        formatTextLeftRight(printer,textL,textR)
      }
      else {
        let textL = 'Trả lại khách'
        let textR = formatCurrent(Math.abs(+item.amounttendered))
        formatTextLeftRight(printer,textL,textR, true)
      }
    })
    printer.addText("-----------------------------------------------\n");

  }
  if(data.customerinfo && data.customerinfo.cardNo && data.type == 3) {
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText(`Hội viên: `);
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText(data.customerinfo.name + '\n');
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText(`Hạng thẻ: `);
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText(data.customerinfo.schemeCode + '\n');

    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText(`Điểm tích lũy: `);
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText(data.customerinfo.IssuedPoints + '\n');
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText(`Điểm khả dụng: `);
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText(data.customerinfo.PointBalance + '\n');

    printer.addText("-----------------------------------------------\n");

  }
  if(data.einvoice && data.type == 3){
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addText(`Thông tin tra cứu hóa đơn: \n`);
    printer.addTextAlign(printer.ALIGN_LEFT);
  
    printer.addText(`Mã tra cứu: `);
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText( data.einvoice?.SINeInvoiceReservationCode+ '\n');
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText(`Tra cứu tại: trungnguyen.einvoice.vn \n`);
    printer.addText("-----------------------------------------------\n");

  }
  
  if(receiptsetups?.footer) {
    // printer.addText(receiptsetups?.footer);
    formatFromTextData(receiptsetups?.footer,printer)
  }
  
  printer.addText("\n \n");

  printer.addCut(printer.CUT_FEED);
  printer.send();
}
export const kitchenPrintEpson = async(printer: any, ePosDev: any, data: any,isDisconnect?: any) => {
  // let TransactionPaymentEntries = data.TransactionPaymentEntries||[]

  if (!printer) {
    popupMessage({icon:'info',title: 'Failed!!', description: 'Failed to connect to printer'});
    return;
  }

  printer.addLayout(printer.LAYOUT_RECEIPT, 576, 0, 0, 0, 10, 10);
  printer.addTextAlign(printer.ALIGN_CENTER);
  printer.addTextSmooth(true);

  printer.addTextSize(2,2)
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addTextLineSpace(10)
  printer.addText(data.sectionname + ' - '+ data.tablename + '\n');
  printer.addTextDouble(false, false);
  printer.addTextSize(1,1)
  printer.addText(data.name?.toUpperCase() + '\n');
  printer.addTextStyle(false, false, false, undefined);//text normal

  printer.addTextLineSpace(10)

  printer.addTextAlign(printer.ALIGN_LEFT);
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Order: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.code + '\t');
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Ngày: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  let date = new Date().toLocaleString('en-GB', { hour12: false })//data.transactiondate+' '+data.transactiontime
  printer.addText(date + '\n');
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('SL khách: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(`${data.noofcovers?data.noofcovers:''}` + '\t');
  // printer.addTextStyle(false, false, true, undefined);//bold
  // printer.addText('Bàn: ');
  // printer.addTextStyle(false, false, false, undefined);//text normal
  // printer.addText(data.tablename + '\n');
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Phục vụ: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(data.nameonreceipt + '\n');

  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText('Ghi chú: ');
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText(`${data.message?data.message:''}` + '\n');
  let {dataInfo} = data
  console.log('dataInfo',dataInfo)
  if(dataInfo) {
    if(dataInfo.title) {
      printer.addTextAlign(printer.ALIGN_CENTER);
      printer.addTextStyle(false, false, true, undefined);//bold
      printer.addTextSize(2,2)
      printer.addText(`**${dataInfo.title}**\n`);
      printer.addTextSize(1,1)
      printer.addTextStyle(false, false, false, undefined);//text normal
      if(dataInfo.description) {
        printer.addText(dataInfo.description+'\n');
      }
      if(dataInfo.staffInfo) {
        let note = `Bởi ${dataInfo.staffInfo.code}`
        if(note) {
          printer.addTextStyle(false, false, false, undefined);//bold
          printer.addText(`(${note})\n`);
          printer.addTextStyle(false, false, false, undefined);//text normal
        }
      }
      // printer.addTextAlign(printer.ALIGN_LEFT);
    }
  }
  printer.addTextAlign(printer.ALIGN_LEFT);
  // Bảng tiêu đề
  printer.addText("+----------------------------------------------+\n");
  printer.addText("| ");
  printer.addTextStyle(false, false, true, undefined);//bold
  printer.addText("Món");
  printer.addText("                                       SL ");
  printer.addTextStyle(false, false, false, undefined);//text normal
  printer.addText("|\n");

  printer.addText("+----------------------------------------------+\n");
  let items  = data?.HospTransactionLines||data.HospTransactionLines||[]
  items.forEach((item: any,index: number) => {
    let itemname = item.description||item.itemname
    // let leng = 0
    let prefix = item.prefix
    if(item.prefix) {
      if( item.prefix == 'GỘP BÀN' || item.prefix == 'CHUYỂN BÀN') {
        prefix = 'CHUYỂN BÀN'
      }
      itemname = `*${prefix}* ${itemname}`
    }
    let itemLines = splitItemName(`${itemname}`, 37);
    itemLines.forEach((line: any, index: number) => {

      if (index === 0) {
        formatItemLineKitchenPrinter(printer,prefix, line, item.quantity.toString())
        // printer.addText(formatItemLineKitchen(line, item.quantity.toString()));
      } else {
        printer.addText(formatItemLineKitchen(line, '',));
      }
    });
    
    // printer.addText("+---------------------------------------------+\n");
    if(item.customizations && item.customizations.length != 0) {
      let toppings = item.customizations
      toppings.forEach((i: any,index: number) => {
        const itemLinesTopping = splitItemName(`${i.description}`, 37);
        itemLinesTopping.forEach((line: any, index: number) => {
          if (index === 0) {
            printer.addText(formatItemLineKitchen(line, i.quantity.toString(), 2));
          } else {
            printer.addText(formatItemLineKitchen(line, '', 2));
          }
        });
      });
      if(!item.message) {
        printer.addText("-----------------------------------------------\n");
      }

    }
    if(item.message) {
      printer.addText(formatItemLineKitchen(item.message, '', 2,'*'));

    }
    if(item.textNote) {
      printer.addTextStyle(false, false, true, undefined);//bold
      printer.addText(formatItemLineKitchen(item.textNote, '', 2,'*'));
      printer.addTextStyle(false, false, false, undefined);//text normal
    }
    if(dataInfo.type == 'voided') {
      printer.addText(formatItemLineKitchen('Lineno: '+item.lineno, '', 2,'*'));
    }
    
    if(!(item.customizations && item.customizations.length != 0 ) || item.message) {
        printer.addText("-----------------------------------------------\n");
    }
  });
  
  
  printer.addText("\n \n \n");

  printer.addCut(printer.CUT_FEED);
  printer.send();
 
};

export const prePrintEpson = async(printer: any, ePosDev: any, data: any,isDisconnect?: any) => {
  // let TransactionPaymentEntries = data.TransactionPaymentEntries||[]
  try {
    if (!printer) {
      popupMessage({icon:'info',title: 'Failed!!', description: 'Failed to connect to printer'});
      return;
    }
  
    // const paperWidth = 576; // Chiều rộng của giấy in, có thể thay đổi theo máy in của bạn
    // const logoWidth = 256; // Chiều rộng của logo, điều chỉnh theo kích thước logo thực tế của bạn
    // const logoHeight = 256; // Chiều cao của logo
  
    // Tính toán vị trí x để canh giữa logo
    // const xPosition = (paperWidth - logoWidth) / 2;
    // const imageUrl = '/public/media/images/logo_sp_trungnguyenlegend.png'; // URL của hình ảnh
    // const base64Image = logoBase64//await convertImageToBase64(imageUrl);
  
    // var canvas = document.getElementById('canvas') as any
    // var context = canvas.getContext('2d');
    // context.drawImage(document.querySelector('app-sidebar-logo-default'), 0, 0, 200, 70);
  
  
    // console.log('base64Image',base64Image)
    printer.addLayout(printer.LAYOUT_RECEIPT, 576, 0, 0, 0, 10, 10);
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addTextSmooth(true);
    // printer.addImage(context, xPosition, 0, logoWidth, logoHeight);
    // printer.addTextSize(2,2)
    // printer.addTextStyle(false, false, true, undefined);//bold
    // printer.addTextLineSpace(50)
    // printer.addText(data.store?.storename + '\n');
    // printer.addTextSize(1,1)
    // printer.addTextStyle(false, false, false, undefined);//text normal
  
    // printer.addTextLineSpace(30)
    // printer.addTextDouble(false, false);
    // printer.addText(`Địa chỉ: ` + data.store?.storename+ '\n');
    // printer.addText('\n');
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addTextSize(2,2)
    printer.addText(data.name + '\n');
    printer.addTextSize(1,1)
    printer.addTextDouble(false, false);
    printer.addText(`(Pre-Print)` + '\n');
    printer.addTextStyle(false, false, false, undefined);//text normal
  
    printer.addTextLineSpace(10)
  
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText('Order: ');
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText(data.code + '\t');
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText('Ngày: ');
    printer.addTextStyle(false, false, false, undefined);//text normal
    let date = new Date().toLocaleString('en-GB', { hour12: false })//data.transactiondate+' '+data.transactiontime
    printer.addText(date + '\n');
    // printer.addTextStyle(false, false, true, undefined);//bold
    // printer.addText('SL khách: ');
    // printer.addTextStyle(false, false, false, undefined);//text normal
    // printer.addText(`${data.noofcovers?data.noofcovers:''}` + '\t');
    printer.addTextStyle(false, false, true, undefined);//bold
    
    // printer.addText('Bàn: ');
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText(data.sectionname + ' - ');
    printer.addText(data.tablename + '\t');
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText('Phục vụ: ');
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText(data.nameonreceipt + '\n');
  
    
    let {dataInfo} = data
    if(dataInfo) {
      if(dataInfo.title) {
        printer.addTextAlign(printer.ALIGN_CENTER);
        printer.addTextStyle(false, false, true, undefined);//bold
        printer.addText(`**${dataInfo.title}**\n`);
        printer.addTextStyle(false, false, false, undefined);//text normal
        if(dataInfo.description) {
          printer.addText(dataInfo.description+'\n');
        }
        if(dataInfo.staffInfo) {
          let note = `Bởi ${dataInfo.staffInfo.code}`
          if(note) {
            printer.addTextStyle(false, false, false, undefined);//bold
            printer.addText(`(${note})\n`);
            printer.addTextStyle(false, false, false, undefined);//text normal
          }
        }
      }
    }
    printer.addTextAlign(printer.ALIGN_LEFT);
    // Bảng tiêu đề
    printer.addText("+---------------------------------------------+\n");
    printer.addText("| ");
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText("Món");
    printer.addText("                                      SL ");
    printer.addTextStyle(false, false, false, undefined);//text normal
    printer.addText("|\n");
  
    printer.addText("+---------------------------------------------+\n");
    
    let items  = data?.HospTransactionLines||data.HospTransactionLines||[]
    items.forEach((item: any,index: number) => {
      let itemname = item.description||item.itemname
      let prefix = item.prefix
      if(item.prefix) {
        if( item.prefix == 'GỘP BÀN' || item.prefix == 'CHUYỂN BÀN') {
          prefix = 'CHUYỂN BÀN'
        }
        itemname = `*${prefix}* ${itemname}`
      }
      const itemLines = splitItemName(`${itemname}`, 37);
      itemLines.forEach((line: any, index: number) => {
        if (index === 0) {
          formatItemLineKitchenPrinter(printer,prefix, line, item.quantity.toString())
          
          // printer.addText(formatItemLineKitchen(line, item.quantity.toString()));
        } else {
          printer.addText(formatItemLineKitchen(line, '',));
        }
      });
      
      // printer.addText("+---------------------------------------------+\n");
      if(item.customizations && item.customizations.length != 0) {
        let toppings = item.customizations
        toppings.forEach((i: any,index: number) => {
          const itemLinesTopping = splitItemName(`${i.description}`, 37);
          itemLinesTopping.forEach((line: any, index: number) => {
            if (index === 0) {
              printer.addText(formatItemLineKitchen(line, i.quantity.toString(), 2));
            } else {
              printer.addText(formatItemLineKitchen(line, '', 2));
            }
          });
        });
        if(!item.message) {
          printer.addText("-----------------------------------------------\n");
        }
  
      }
      if(item.message) {
        printer.addText(formatItemLineKitchen(item.message, '', 2,'*'));
  
      }
      if(!(item.customizations && item.customizations.length != 0 ) || item.message) {
          printer.addText("-----------------------------------------------\n");
      }
    });
    printer.addText("\n");
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText('Quý Khách cần xuất hóa đơn VAT vui lòng \n');
    printer.addText('thông báo trước khi thanh toán');
    printer.addText("\n \n \n");
  
    printer.addCut(printer.CUT_FEED);
    
    printer.send();
    
  }  catch (error: any) {
    // console.error('Lỗi khi in:', error, error.message);
    // console.log('Lỗi khi in:', error, error.message);
    // Thêm xử lý lỗi nếu cần, ví dụ: hiển thị thông báo cho người dùng
}
  
  
};
const leng = (text: string) => {
  let line = 47
  return line - text.length
}
const formatTextLeftRight = (printer: any, textLeft: string, textRight: string, strong?: any) => {
  printer.addTextAlign(printer.ALIGN_LEFT);
  printer.addTextStyle(false, false, false, undefined);//text normal
  if(strong) {
    printer.addTextStyle(false, false, true, undefined);//bold
  }
  printer.addText(textLeft);
  if(!strong) {
    printer.addTextStyle(false, false, false, undefined);//text normal
    // printer.addTextStyle(false, false, false, printer.COLOR_1);//text normal
  }
  printer.addTextAlign(printer.ALIGN_RIGHT);
  printer.addText(`${padText(textRight,leng(textLeft),'right')} \n`);
}
const padText = (text: string, length: number, align: 'left' | 'center' | 'right' = 'left'): string => {
  if (align === 'left') {
    return text.padEnd(length);
  } else if (align === 'right') {
    return text.padStart(length);
  } else {
    const padding = length - text.length;
    const padLeft = Math.floor(padding / 2);
    const padRight = Math.ceil(padding / 2);
    return ' '.repeat(padLeft) + text + ' '.repeat(padRight);
  }
};
const splitItemName = (name: string, maxLength: number,customLeng?: any) => {
  const lines: string[] = [];
  let leng = 0
  if(customLeng) {
    leng = customLeng
  }
  let startIndex = 0;
  while (startIndex < name.length) {
    lines.push(name.substring(startIndex, startIndex + maxLength).padEnd(maxLength, ' '));
    startIndex += maxLength;
  }
  return lines;
};
const formatItemLine = (name: string, quantity: string, price: string, total: string) => {
  const formattedQuantity = quantity.padStart(2, ' ');
  const formattedPrice = price.padStart(7, ' ');
  const formattedTotal = total.padStart(10, ' ');
  return `| ${name.padEnd(15, ' ')} | ${formattedQuantity} | ${formattedPrice} | ${formattedTotal} |\n`;
};
const formatItemLineKitchen = (name: string, quantity: string,ps?: any,symbol?: any) => {
  let padDf = 39
  let a = ''
  if(ps) {
    padDf = padDf - ps - 4
    for(let i = 1; i<ps; i++) {
      a += ' '
    }
    if(symbol) {
      a += symbol
    }
    else {
      a += '+'
    }
  }
  const formattedQuantity = quantity.padStart(2, ' ');
  
  
  return `  ${a}${name.padEnd(padDf, ' ')}  ${formattedQuantity}  \n`;
};
const formatItemLineKitchenPrinter = (printer: any,prefix: string, name: string, quantity: string,ps?: any,symbol?: any) => {
  let padDf = 39
  let a = ''
  if(ps) {
    padDf = padDf - ps - 4
    for(let i = 1; i<ps; i++) {
      a += ' '
    }
    if(symbol) {
      a += symbol
    }
    else {
      a += '+'
    }
  }
  const formattedQuantity = quantity.padStart(2, ' ');
  
  if(prefix) {
    printer.addTextStyle(false, false, true, undefined);//bold
    printer.addText(`  ${a}*${prefix}*`);
    printer.addTextStyle(false, false, false, undefined);//text normal
    let leng = prefix.length+2
    name = name.substring(leng)
    printer.addText(`${name.padEnd(padDf - leng, ' ')}  ${formattedQuantity}  \n`);
  }
  else {
    printer.addText(`  ${a}${name.padEnd(padDf, ' ')}  ${formattedQuantity}  \n`);

  }
  
  // return `  ${a}${name.padEnd(padDf, ' ')}  ${formattedQuantity}  \n`;
};