import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, ApiShowError, parentLink, sortByUpadateAt, sortWithDate } from "../../../theme/helpers";
import { ContentHeader } from "../../../theme/layout/components/content";
import { DivRoot, FilterDropdown, InputDate, KTSVG } from "../../../theme/partials";
import { useTransactionsCounttoday } from "../../modules/api/transactions";
import { getAuth, useAuth } from "../../modules/auth";
import { useStoreMode, useStorePrint } from "../../modules/auth/core/store";
import { ActiveOrder } from "./tabs/activeOrder";
import { ModalAddEditOrder } from "./tabs/addOrder";
import { CancelOrder } from "./tabs/cancelOrder";
import { CompleteOrder } from "./tabs/completeOrder";
import { MLive } from "./tabs/live";
import { ReadyOrder } from "./tabs/readyOrder";
import { useTranslation } from "react-i18next";
import { getInforDataToPrint } from "./tabs/functionOrder";
import localforage from "localforage";


const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});

const OrderManagement = () => {
    // const { permission } = getLocationData()
    const navigate = useNavigate();
    const { id } = useParams()
    const [tableData,setTableData] = useState<any>([])
    const {pathname} = useLocation()
    const { currentUser } = useAuth() as any
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}

    const { data: dataCount, isSuccess, refetch } = useTransactionsCounttoday(`${staffInfo.merchantid}/counttoday`)
    let storePrint = useStorePrint((e: any)=> e)||{}

    // const dataQuery = {}
 
    useEffect(() => {
      dataForTab('Open')
    }, [])
    
    const [tab, setTab] = useState('tab-0')
    const [filter, setFilter] = useState<any>(null)
    // const [isSearch, setisSearch] = useState<any>(false)
    const [sortTime,setSortTime] = useState(0)
    const [searchOn,setSearchOn] = useState(false)
    
    const [edit, setEdit] = useState(false)
    const [dataModal ,setDataModal] = useState<any>({})
    const [dataTab ,setDataTab] = useState<any>([])
    const [statusActive ,setStatusActive] = useState<any>('Open')
    const [isClickTab, setIsClickTab] = useState(false)
    
    const [dataNotify,setDataNotify] = useState<any>(null)
    const ws = useRef<any>(null);
    const intervalId = useRef<any>(null);
    useEffect(() => {
      if(dataNotify) {
        const printEinvoice = async() => {
            let infoSetupPrint = await localforage.getItem("merchantsData")||{} as any
            let username = (await getAuth() as any).user?.username

            let {receiptsetups} = infoSetupPrint||{}
            if(username == dataNotify.username && receiptsetups.closedcheckreceipt == 'printInvoice') {
              getInforDataToPrint(dataNotify?.id, staffInfo, storePrint,3)
            }
        }
        if(dataNotify?.einvoice) {
          printEinvoice()
        }
      }
    },[dataNotify])
    const connectWebSocket = () => {
      const url = process.env.REACT_APP_URL_API as string
      ws.current = new WebSocket(url);

      ws.current.onopen = () => {
          console.log('WebSocket connected');
          // Send a keep-alive message every 30 seconds
          intervalId.current = setInterval(() => {
              if (ws.current.readyState === WebSocket.OPEN) {
                  ws.current.send(JSON.stringify({ type: 'keep-alive' }));
              }
          }, 30000);
      };

      ws.current.onmessage = (event: any) => {
        let wsdata = event.data;

        if (wsdata) {
          wsdata = JSON.parse(wsdata);
          console.log('WebSocket server wsdata', wsdata) //wsdata {"id":"69a33d3c-ad3a-448f-8623-fe12c65effa2","code":"24060000313","kitchenstatus":"Not Sent","transactionstatus":"Open"}
          if(wsdata.merchantcode == staffInfo.Merchant?.merchantcode || wsdata.merchantid == staffInfo.merchantid) {
            setDataNotify(wsdata)
            let status = watch('tab')
            refetch()
            // if(wsdata.einvoice) {
            // }
            if(wsdata.createdPaymententries) {
              dataForTab(status)
            }
            else if(status == wsdata.transactionstatus || status == wsdata.prevTransactionstatus) {
              dataForTab(wsdata.prevTransactionstatus||wsdata.transactionstatus||'Open')
            }
            let a = document.querySelector('.modal-fullscreen')
            // console.log('aa',a)
            if(!wsdata.prevTransactionstatus && status == 'Open' && !a) {
              // console.log('play')
              setTimeout(() => {
                playAudio()
              },500)
            }
          }
          
        }
      };

      ws.current.onclose = () => {
          console.log('WebSocket closed.');
          clearInterval(intervalId.current);
          if(window.location.pathname == '/mode/orders-hub') {
            console.log('WebSocket Reconnecting...')
            setTimeout(()=>{
              connectWebSocket()
            }, 1000); // Reconnect after 1 second
          }
      };

      ws.current.onerror = (error: any) => {
          console.log('WebSocket error:', error);
          ws.current.close();
      };
    };
    useEffect(() => {
      connectWebSocket();
      return () => {
        ws.current.close();
        clearInterval(intervalId.current);
      };
    }, []);
    
    const getCount = (status: string) => {
      return dataCount?.find((i: any) => i.transactionstatus == status)?.count || 0
    }
    const dataForTab = async(status: string) => {
      let param = '&expand=TransactionPaymentEntries'
      if(status == 'Completed' || status == 'Cancel') {
        param = '&expand=DiningTableHistoryEntries,TransactionPaymentEntries'
      }
      try {
        const urlApi = `/smarthub/hosptransactions/${staffInfo.merchantid}/current?transactionstatus=${status}${param}`
        let {data} = await ApiService.get(urlApi)
        // console.log('data',data)
        data = sortWithDate(data,{sort:'modifiedat',order:'desc'})
        setDataTab(data)
        setStatusActive(status)
        let a = document.querySelector('.modal-fullscreen')
        if(status == 'Open' && data.length != 0 && !a && isClickTab) {
          playAudio()
        }
        else {
          // stopAudio()
        }
      } catch (error) { ApiShowError(error) }
      
    }
    const { t } = useTranslation()
    const tabs = [
      { id: 'tab-0', status:'Open', name: t('orders-hub-deep.needs-approval'), lengData: dataTab.length, component: <MLive isSearch={searchOn} setSearchOn={setSearchOn} filter={filter} setFilter={setFilter} dataApi={dataTab} callBackReFetch={refetch} dataNotify={dataNotify} setIsClickTab={setIsClickTab} isClickTab={isClickTab}/> },
      { id: 'tab-2', status:'Active', name: t('orders-hub-deep.active'), lengData: dataTab.length, component: <ActiveOrder dataApi={dataTab} callBackReFetch={refetch} dataNotify={dataNotify} setIsClickTab={setIsClickTab} isClickTab={isClickTab}/> },
      // { id: 'tab-3', status:'Ready', name: t('orders-hub-deep.order-ready'), lengData: dataTab.length, component: <ReadyOrder dataApi={dataTab} callBackReFetch={refetch} dataNotify={dataNotify} setIsClickTab={setIsClickTab} isClickTab={isClickTab}/> },
      { id: 'tab-4', status:'Completed', name: t('orders-hub-deep.completed'), lengData: dataTab.length, component: <CompleteOrder dataApi={dataTab} callBackReFetch={refetch} dataNotify={dataNotify} setIsClickTab={setIsClickTab} isClickTab={isClickTab}/> },
      { id: 'tab-1', status:'Cancel', name: t('orders-hub-deep.cancel'), lengData: dataTab.length, component: <CancelOrder dataApi={dataTab} callBackReFetch={refetch} dataNotify={dataNotify} setIsClickTab={setIsClickTab} isClickTab={isClickTab}/> },
      
    ]
    let staffPermissionGroupInfo = useStoreMode((e: any)=> e.info)?.staffPermissionGroupInfo || {}

    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
          tab: "Open"
        }
    })
    const [isPlaying,setIsPlaying] = useState(false)
    function playAudio() {
      let audio = document.getElementById("myAudio") as any
      console.log('audio',audio)
      // return
      if(audio) {
        audio?.play();
        audio.muted = true; // Tắt tiếng ban đầu
        audio.play()
          .then(() => {
            console.log('Audio is playing in muted mode.');
            audio.muted = false; // Bật tiếng sau khi phát thành công
            audio.loop = true
          })
          .catch((error: any) => {
            console.error('Error playing audio:', error);
          });
        setIsPlaying(true)
      }
    }
    function stopAudio() {
      let audio = document.getElementById("myAudio") as any
      if(audio) {
        audio.pause();
        audio.currentTime = 0;
        setIsPlaying(false)
      }
      
    }
    function toggleAudio() {
      let audio = document.getElementById("myAudio") as any
      audio?.paused ? playAudio() : stopAudio();
    }
    useEffect(() => {
      
    }, [])
    
    const filterBtn = <>
    <FilterDropdown
    btnTemplate={<div className="btn btn-light-primary btn-active-primary btn-sm btn-icon">
      <KTSVG path={'/media/icons/custom/filter.svg'} className={clsx('svg-icon-2 svg-icon')} />
    </div>
    }
    width="350"
    onClose={() => {
        setFilter(undefined)
        reset({})
        }
    }
    handleSubmit={handleSubmit}
    onSubmit={(data:any) => {
      // console.log('ấda',data)
        setFilter(data)
       
    }} >
        <div className="container-fluid mb-2">
            <div className="row">
              <div className="col-12 fs-5 mb-3">{t('orders-hub-deep.pay-status')}</div>
              <div className="col-12 col-md-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('notPaid', {
                        onChange: (e) => {} })} value='1' id='not_paid' />
                  <label htmlFor="not_paid">{t('orders-hub-deep.not-paid')}</label>
                </div>
              </div>
              
              <div className="col-12 col-md-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('isPaid', {
                        onChange: (e) => {} })} value={1} id='is_paid' />
                  <label htmlFor="is_paid">{t('orders-hub-deep.paid')}</label>
                </div>
              </div>
              
              <div className="col-12"><hr /></div>
              <div className="col-12 fs-5 mb-3">{t('orders-hub-deep.dinner-behavior')}</div>
              <div className="col-12 col-sm-6">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('dinein', {
                        onChange: (e) => {} })} value={1} id="dinein" />
                  <label htmlFor="dinein">{t('orders-hub-deep.dinner-in')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('takeout_in', {
                        onChange: (e) => {} })} value={1} id='takeout_in' />
                  <label htmlFor="takeout_in">{t('orders-hub-deep.takeout-in')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6  mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('is_delivery', {
                        onChange: (e) => {} })} value={1} id='is_delivery' />
                  <label htmlFor="is_delivery">{t('orders-hub-deep.delivery')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('is_curbside', {
                        onChange: (e) => {} })} value={1} id='is_curbside' />
                  <label htmlFor="is_curbside">{t('orders-hub-deep.curbside')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('no_behavior', {
                        onChange: (e) => {} })} value='1' id='no_behavior' />
                  <label htmlFor="no_behavior">{t('orders-hub-deep.no-behavior')}</label>
                </div>
              </div>
            </div>
        </div>
      </FilterDropdown>
    </>
    const toolbar = <div className="d-flex flex-center gap-5">
      <div className="me-10">
        <span>{t('orders-hub-deep.takeout')}: {t('orders-hub-deep.number-min', { number: '15'})} - {t('orders-hub-deep.number-min', { number: '20' })} | {t('orders-hub-deep.delivery')}: {t('orders-hub-deep.number-min', { number: '45' })} - {t('orders-hub-deep.number-min', { number: '50' })}</span>
      </div>
      {filterBtn}
      <Dropdown>
        <Dropdown.Toggle as={'div'} className='btn btn-light-primary btn-active-primary btn-sm btn-icon h-el-after'>
        <i className="bi bi-arrow-down-up"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px p-3' >
            
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                {/* <a href='#' className=''> */}
                  <span className="">{t('orders-hub-deep.sort-by')}</span>
                {/* </a> */}
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <div className="form-check form-check-sm form-check-custom form-check-solid flex-stack cursor-pointer">
                  <label htmlFor="check1">{t('orders-hub-deep.due-time')} - {t('orders-hub-deep.first')}</label>
                  <input className="form-check-input ms-auto" type="radio" checked={sortTime == 1} value='1' onChange={e=> sortTime != 1 ? setSortTime(1):setSortTime(0)} id='check1' />
                </div>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <div className="form-check form-check-sm form-check-custom form-check-solid flex-stack cursor-pointer">
                  <label htmlFor="check2">{t('orders-hub-deep.due-time')} - {t('orders-hub-deep.last')}</label>
                  <input className="form-check-input ms-auto" type="radio" checked={sortTime == 2} value='2' onChange={e=> sortTime != 2 ? setSortTime(2):setSortTime(0)} id='check2' />
                </div>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <div className="form-check form-check-sm form-check-custom form-check-solid flex-stack cursor-pointer">
                  <label htmlFor="check3">{t('orders-hub-deep.order-number')} - {t('orders-hub-deep.oldest')}</label>
                  <input className="form-check-input ms-auto" type="radio" checked={sortTime == 3} value='3' onChange={e=> sortTime != 3 ? setSortTime(3):setSortTime(0)} id='check3' />
                </div>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <div className="form-check form-check-sm form-check-custom form-check-solid flex-stack cursor-pointer">
                  <label htmlFor="check4">{t('orders-hub-deep.order-number')} - {t('orders-hub-deep.newest')}</label>
                  <input className="form-check-input ms-auto" type="radio" checked={sortTime == 4} value='4' onChange={e=> sortTime != 4 ? setSortTime(4):setSortTime(0)} id='check4' />
                </div>
            </Dropdown.Item>
            
        </Dropdown.Menu>
      </Dropdown>

      <span className="btn btn-light-primary btn-active-primary btn-sm btn-icon cursor-pointer" onClick={e=> setSearchOn(true)}><i className="bi bi-search"></i></span>
      {
        staffPermissionGroupInfo.isopensalepos &&
        <span className="btn btn-light-primary btn-active-primary btn-sm btn-icon cursor-pointer" onClick={e=>setEdit(true)}><i className="bi bi-plus-circle"></i></span>
      }
      <Dropdown>
        <Dropdown.Toggle as={'div'} className='btn btn-light-primary btn-active-primary btn-sm btn-icon h-el-after'>
          <i className="bi bi-three-dots-vertical" />
        </Dropdown.Toggle>
        <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px p-2' >
            
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <a href='#' className=''>
                  <span className="menu-link">{t('orders-hub-deep.manager-online-orders')}</span>
                </a>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <a href='#' className=''> 
                  <span className="menu-link">{t('orders-hub-deep.find-checks-and-issue-result')}</span>
                </a>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <a href='#' className=''> 
                  <span className="menu-link">{t('orders-hub-deep.switch-users')}</span>
                </a>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <a href='#' className=''> 
                  <span className="menu-link">{t('orders-hub-deep.device-status')}</span>
                </a>
            </Dropdown.Item>
            
        </Dropdown.Menu>
    </Dropdown>
    
    </div>
    
    const createHospTrans = async(tableInfo: any, item?: any) => {
      let tempData = {
          "saletype": tableInfo?.qrcodeordertype,
          "merchantkey": staffInfo.Merchant?.merchantkey,
          "merchantcode": staffInfo.Merchant?.merchantcode,
          "merchantname": staffInfo.Merchant?.merchantname,
          "merchantid": staffInfo.merchantid,
          "storeno": staffInfo.Merchant?.storecode,
          "tableid": item?.tableid?item.tableid:tableInfo?.id,
          "tableno": item?.tableno?item.tableno:tableInfo?.tableno,
          "tablename": item?.tablename?item.tablename:tableInfo?.tablename,
          "sectionid": tableInfo?.sectionid,
          "sectioncode": tableInfo?.sectioncode,
          "sectionname": tableInfo?.sectionname,
          "transactiontype": "Sales",
          "transactionstatus": "Open",
          "kitchenstatus": "Not Sent",
          
          "staffcode": staffInfo.code,
          "staffreceiptname": staffInfo.nameonreceipt,
          "noofitems": 1,
          "noofitemlines": 1,
          "linediscount": 0,
          "totaldiscount": 0,
          "customerinfo": {
              "name": "Guest"
          },
          "HospTransactionLines": []
      }
      let urlApi = `smarthub/hosptransactions/create`
      try {
        let {data} = await ApiService.post(urlApi, tempData)
        if(data && data.upsertedHospTransaction) {
          let temp = data.upsertedHospTransaction
          setDataModal(temp)
        }  
      } catch (error) {
        
      }
      
    }
    const onSubmitFilter:any = (data: any) => {
        if(!filter) return data
        let tempData = data.map((item:any)=> item)
        if(filter.statusLocal ) {
            tempData = tempData.filter((i:any)=> i.statusLocal == filter.statusLocal)
        }
        if(filter.departmentcode) {
            tempData = tempData.filter((i:any)=> i.departmentid == filter.departmentid)
        }
        if(filter.createdby) {
            tempData = tempData.filter((i:any)=> i.createdby == filter.createdby)
        }
        let fromdate = filter.fromdate ? filter.fromdate: new Date()
        if(fromdate) {
            fromdate = new Date(fromdate).setHours(0,0,0)
        }
        let todate = filter.todate? filter.todate[0]: null 
        if(todate) {
            todate = new Date(todate).setHours(23,59,59)
        }
        if(todate) {
            tempData = tempData.filter((i:any)=> new Date(i.createdat).getTime() <= todate && new Date(i.createdat).getTime() >= fromdate)
        }
        // console.log('tempData',tempData)
        return tempData
    }

    
    
    return <>
        <ContentHeader title={t('orders-hub')} linkBack={'/mode'} items={[{ title: t('mode'), path: parentLink(pathname) }]} elements={toolbar} />
        <div className='container-fluid border-gray-300 mh-auto pb-0'>
              <div className='mb-0 row'>
                <div className="col-7 position-relative ps-0">
                <div
                    className='card flex-fill d-flex flex-row text-nowrap tab-custom-hub'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-width='auto'
                    data-kt-scroll-offset='0'
                  >
                    {tabs.map((item, index) => {
                      return (<span
                        key={index}
                        id={item.id}
                        onClick={async() => {
                          
                          await dataForTab(item.status)
                          setTab(item.id)
                          setStatusActive(item.status)
                          setValue('tab',item.status)
                          setIsClickTab(true)
                          // console.log('click tab active',item.status)
                          // refetch()
                        }}
                        className={clsx(
                          ' cursor-pointer item-tab position-relative pe-8',
                          tab != item.id && 'text-gray-600 ',
                          tab == item.id && 'active',
                          index == 0 && ''
                        )}>
                        {item.name} <span className={clsx(`number-circle bg-${tab == item.id?'primary circle-custom':'gray right-3px'} position-absolute `)}>{getCount(item.status)}</span>
                      </span>)
                    })}
                    
                  </div>
                  <span onClick={e=>{
                    toggleAudio()
                  }} className="position-absolute volume-custom cursor-pointer"><i className={clsx(`fs-2 text-dark bi bi-volume-${isPlaying?'up':'mute'}-fill`)}></i></span>

                </div>
                
              </div>
              
              {tabs.map((item, index) => {
                  return <DivRoot key={index}>
                      {item.id == tab && item.component}
                  </DivRoot>
              })}
            </div>
        
        <InputDate
          className={clsx('form-control form-control-sm w-100 d-none',)}
          value={''}
          
          onChange={e=>{}}
          />
        <audio id="myAudio">
          <source src="/media/audio/beep.mp3" type="audio/mpeg"/>
        </audio>
        <input {...register('tab')} className="form-control form-control-sm d-none" type="text" />

    <ModalAddEditOrder createHospTrans={createHospTrans} refetchCurrent={refetch} show={edit} dataModal={dataModal} setDataModal={setDataModal} setShow={setEdit}/>
        
        
    </>
}

export { OrderManagement };

