import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, parentLink } from "../../../theme/helpers";
import { ContentHeader } from "../../../theme/layout/components/content";
import { InputDate } from "../../../theme/partials";
import { useCountPaymentTerminal } from "../../modules/api/transactions";
import { getAuth, useAuth } from "../../modules/auth";
import { useStoreMode, useStorePrint } from "../../modules/auth/core/store";
import { ModalAddEditOrder } from "../orderManagement/tabs/addOrder";
import { MBar } from "./tabs/live";
import { useTranslation } from "react-i18next";
import { AuthLayoutMode } from "../mode/authMode";
import { checkInternetConnection } from "../tableService";
import localforage from "localforage";
import { getInforDataToPrint } from "../orderManagement/tabs/functionOrder";


const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});
export const salestype_arr = [{
  label: 'DINE-IN',
  value: 'dinein',
},{
  label: 'DELIVERY',
  value: 'is_delivery'
},{
  label: 'TAKEAWAY',
  value: 'takeaway'
}
]
const PaymentTerminal = () => {
    // const { permission } = getLocationData()
    const navigate = useNavigate();
    const [status,setStatus] = useState('Open')
    const [tableData,setTableData] = useState<any>([])
    const {pathname} = useLocation()
    const { currentUser } = useAuth() as any
    //smarthub/:merchantid/uncompleted
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}
    // const { data: dataQuery, isSuccess, refetch, isFetching } = usePaymentTerminal(`${staffInfo.merchantid}`,`?staffcode=${staffInfo.code}&transactionstatus=${status}`)
    const { data: dataCount, isSuccess, refetch, isFetching } = useCountPaymentTerminal(null,``)//${staffInfo.merchantid}
    const [countDataTabs,setCountDataTabs] = useState()
    const [keyword,setKeyword] = useState('')
    const [filter, setFilter] = useState<any>(null)
    const [sortByFilter, setSortByFilter] = useState('most_time')
    const [tab, setTab] = useState('Open')
    let staffPermissionGroupInfo = useStoreMode((e: any)=> e.info)?.staffPermissionGroupInfo || {}
    useEffect(() => {
      if(!staffPermissionGroupInfo.isaddpayment) {
        navigate('/mode')
      }
    },[])
    useEffect(()=>{
      if(!isFetching && dataCount) {
        setCountDataTabs(dataCount)
      }  
    },[isFetching])
    useEffect(()=>{
      if(filter != null) {
        dataForTab(status,{
          ...pageOption,
          page: 1,
        })
      }
    },[filter])
    
    const callBackFunc = (status: any) => {
      // console.log('status',status)
      setStatus(status)
      setValue('tab',status)
      dataForTab(status,{
        ...pageOption,
        page: 1,
      })
    }
    // useEffect(() => {
    //   if(!isFetching && dataQuery) {
    //     let tempData = sortWithDate(dataQuery,{order:'desc',sort:'transactiontime'})
    //     console.log('tempData',tempData)
    //     setTableData(tempData)
    //   }  
    // }, [isFetching])
    // const [isSearch, setisSearch] = useState<any>(false)
    const [sortTime,setSortTime] = useState(0)
    const [searchOn,setSearchOn] = useState(false)
    const [edit, setEdit] = useState(false)
    const [dataModal ,setDataModal] = useState<any>({})
    const [dataTab ,setDataTab] = useState<any>([])
    const [isloading,setIsloading] = useState(false)
    const [showLogin,setShowLogin] = useState(false)
    
    const [isOnline,setIsOnline] = useState(navigator.onLine)
        useEffect(() => {
          // Hàm cập nhật trạng thái online/offline
          const updateOnlineStatus = async () => {
            const onlineStatus = await checkInternetConnection();
            setIsOnline(onlineStatus);
          };
      
          // Gọi hàm kiểm tra kết nối ban đầu
          updateOnlineStatus();
      
          // Lắng nghe sự thay đổi của sự kiện online/offline từ trình duyệt
          const handleOnline = () => {
            updateOnlineStatus();
          };
      
          const handleOffline = () => {
            setIsOnline(false);
          };
      
          window.addEventListener('online', handleOnline);
          window.addEventListener('offline', handleOffline);
      
          // Cleanup event listener khi component unmount
          return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
          };
        }, []);

    useEffect(() => {
      dataForTab('Open',pageOption)
    }, [])
    const [pageOption, setPageOption] = useState({ page: 1, limit: 50, total: 50 })
    const dataForTab = async(status: string,pageOption: any,keyword?: any) => {
      let param = ''
      if(status == 'Completed' || status == 'Cancel') {
        param = '&expand=DiningTableHistoryEntries'
      }
      if(keyword) {
        param += `&keyword=${keyword}`
      }
      if(filter) {
        
        let saletype = [] as any
        salestype_arr.map((i: any) => {
          if(filter[i.value]) {
            saletype.push(i.label)
          }
        })
        if(saletype && saletype.length != 0){
          param += `&saletype=${saletype.join(',')}`
        } 
      }
      setIsloading(true)

      const urlApi = `/smarthub/hosptransactions/${staffInfo.merchantid}/allday?transactionstatus=${status}${param}&page=${pageOption.page}&pagesize=${pageOption.limit}`//staffcode=${staffInfo.code}&
      let {data} = await ApiService.get(urlApi)
      // console.log('data',data)
      if(data) {
        data.items.forEach((item: any) => {
          item.createddate = item.modifiedat
          if(item.DiningTableHistoryEntries && item.DiningTableHistoryEntries[0]) {
            item.createddate = item.DiningTableHistoryEntries[0]?.createddate
          } 
        })
      }
      // console.log('items',data)
      setPageOption({...pageOption,total: data.total})
      if(pageOption.page == 1) {
        setDataTab(data.items)
      }
      else {
        setDataTab(dataTab.concat(data.items))
      }
      setIsloading(false)

      // setStatusActive(status)
      
    }
    const ws = useRef<any>(null);
    const [dataNotify,setDataNotify] = useState<any>(null)
    let storePrint = useStorePrint((e: any)=> e)||{}
    
    useEffect(() => {
      if(dataNotify) {
        const printEinvoice = async() => {
          let infoSetupPrint = await localforage.getItem("merchantsData")||{} as any
          let username = (await getAuth() as any).user?.username
          let {receiptsetups} = infoSetupPrint||{}
          if(receiptsetups.closedcheckreceipt == 'printInvoice' && username == dataNotify.username) {
            getInforDataToPrint(dataNotify?.id, staffInfo, storePrint,3)
          }
        }
        if(dataNotify?.einvoice) {
          printEinvoice()
        }
      }
    },[dataNotify])
    const intervalId = useRef<any>(null);
    const connectWebSocket = () => {
      const url = process.env.REACT_APP_URL_API as string
      ws.current = new WebSocket(url);

      ws.current.onopen = () => {
          console.log('WebSocket connected');
          // Send a keep-alive message every 30 seconds
          intervalId.current = setInterval(() => {
              if (ws.current.readyState === WebSocket.OPEN) {
                  ws.current.send(JSON.stringify({ type: 'keep-alive' }));
              }
          }, 30000);
      };

      ws.current.onmessage = (event: any) => {
        let wsdata = event.data;

        if (wsdata) {
          wsdata = JSON.parse(wsdata);
          
          console.log('WebSocket server wsdata', wsdata) //wsdata {"id":"69a33d3c-ad3a-448f-8623-fe12c65effa2","code":"24060000313","kitchenstatus":"Not Sent","transactionstatus":"Open"}
          if(wsdata.merchantcode == staffInfo.Merchant?.merchantcode || wsdata.merchantid == staffInfo.merchantid) {
            setDataNotify(wsdata)
            let status = tab
            refetch()
            if(status == wsdata.transactionstatus || status == wsdata.prevTransactionstatus) {
              dataForTab(tab,pageOption)
            }
          }
          
          
        }
      };

      ws.current.onclose = () => {
          console.log('WebSocket closed.');
          clearInterval(intervalId.current);
          if(window.location.pathname == '/mode/payment-terminal') {
            console.log('WebSocket Reconnecting...')
            setTimeout(()=>{
              connectWebSocket()
            }, 1000); // Reconnect after 1 second
          }
      };

      ws.current.onerror = (error: any) => {
          console.log('WebSocket error:', error);
          ws.current.close();
      };
    };
    useEffect(() => {
      connectWebSocket();
      return () => {
        ws.current.close();
        clearInterval(intervalId.current);
      };
    }, []);
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
          tab: "Open"
        }
    })

    const createHospTrans = async(tableInfo: any, item?: any) => {
      let tempData = {
          "saletype": tableInfo?.qrcodeordertype,
          "merchantkey": staffInfo.Merchant?.merchantkey,
          "merchantcode": staffInfo.Merchant?.merchantcode,
          "merchantname": staffInfo.Merchant?.merchantname,
          "merchantid": staffInfo.merchantid,
          "storeno": staffInfo.Merchant?.storecode,
          "tableid": item?.tableid?item.tableid:tableInfo?.id,
          "tableno": item?.tableno?item.tableno:tableInfo?.tableno,
          "tablename": item?.tablename?item.tablename:tableInfo?.tablename,
          "sectionid": tableInfo?.sectionid,
          "sectioncode": tableInfo?.sectioncode,
          "sectionname": tableInfo?.sectionname,
          "transactiontype": "Sales",
          "transactionstatus": "Open",
          "kitchenstatus": "Not Sent",
          
          "staffcode": staffInfo.code,
          "staffreceiptname": staffInfo.nameonreceipt,
          "noofitems": 1,
          "noofitemlines": 1,
          "linediscount": 0,
          "totaldiscount": 0,
          "customerinfo": {
              "name": "Guest"
          },
          "HospTransactionLines": []
      }
      let urlApi = `smarthub/hosptransactions/upsertwithdetails/`
      let {data} = await ApiService.post(urlApi, tempData)
      setDataModal(data)
    }
    
    const { t } = useTranslation()
    const toolbar = <div className="d-flex flex-center gap-5">
      <span className="text-primary">{t('table-service-deep.version')} {process.env.REACT_APP_VERSION}</span>
      {
        staffInfo && 
        <span className="text-primary"><i className="bi bi-person-hearts text-reset me-1"></i>{staffInfo.code} | {staffInfo.fullname}</span>
      }
    {
      isOnline ?
      <span className="text-primary cursor-pointer"><i className="bi bi-wifi me-2 text-reset"></i> {t('online')}</span>
      :
      <span className="text-gray cursor-pointer"><i className="bi bi-wifi-off me-2 text-reset"></i> {t('offline')}</span>
    }
      {/* {staffPermissionGroupInfo.isopensalepos && 
        <span className="btn btn-light-primary btn-active-primary btn-sm cursor-pointer" onClick={e=>{
          // setIsNewOrder(true)
          // setEdit(!edit)
        }}><i className="bi bi-search me-2"></i> Lookups</span>
      } */}
      <Dropdown>
        <Dropdown.Toggle as={'div'} className='btn btn-light-primary btn-active-primary btn-sm btn-icon h-el-after'>
          <i className="bi bi-three-dots-vertical" />
        </Dropdown.Toggle>
        <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px p-2' >
            
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <span className=''>
                  <span className="menu-link">{t('table-service-deep.manager-online-orders')}</span>
                </span>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <span className=''> 
                  <span className="menu-link">{t('user-guide')}</span>
                </span>
            </Dropdown.Item>
            
            
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <span onClick={e=>window.location.reload()} className='text-black'> 
                  <span className="menu-link d-flex gap-2 align-items-center">{t('order-deep.refresh')}</span>
                </span>
            </Dropdown.Item>
            {/* <Dropdown.Item as='div'  className={clsx('menu-item')} >
                <span className=''> 
                  <span className="menu-link">{t('table-service-deep.version')} {process.env.REACT_APP_VERSION}</span>
                </span>
            </Dropdown.Item> */}
        </Dropdown.Menu>
    </Dropdown>
    <span onClick={e=> {
      setShowLogin(true)
      // storeMode.setMode(null)
      // console.log('storeMode',storeMode)
    }} className="btn btn-light-primary btn-active-primary btn-sm cursor-pointer"><i className="bi bi-arrow-left-right me-2"></i>{t('table-service-deep.switch-user')}</span>
    
    </div>
   
    

    const onSubmitFilter:any = (data: any) => {
        if(!filter) return data
        let tempData = data.map((item:any)=> item)
        if(filter.statusLocal ) {
            tempData = tempData.filter((i:any)=> i.statusLocal == filter.statusLocal)
        }
        if(filter.departmentcode) {
            tempData = tempData.filter((i:any)=> i.departmentid == filter.departmentid)
        }
        if(filter.createdby) {
            tempData = tempData.filter((i:any)=> i.createdby == filter.createdby)
        }
        let fromdate = filter.fromdate ? filter.fromdate: new Date()
        if(fromdate) {
            fromdate = new Date(fromdate).setHours(0,0,0)
        }
        let todate = filter.todate? filter.todate[0]: null 
        if(todate) {
            todate = new Date(todate).setHours(23,59,59)
        }
        if(todate) {
            tempData = tempData.filter((i:any)=> new Date(i.createdat).getTime() <= todate && new Date(i.createdat).getTime() >= fromdate)
        }
        // console.log('tempData',tempData)
        return tempData
    }

    
    return !staffPermissionGroupInfo.isaddpayment ? <></>:<>
        <ContentHeader title={t('payment-terminal')} linkBack={'/mode'} items={[{ title: t('mode'), path: parentLink(pathname) }]} elements={toolbar} />
        
        <MBar setTab={setTab} tab={tab} dataNotify={dataNotify} setSortByFilter={setSortByFilter} sortByFilter={sortByFilter} setFilter={setFilter} filter={filter} setKeyword={setKeyword} setPageOption={setPageOption} pageOption={pageOption} refetchDataTab={dataForTab} dataApi={dataTab} isloading={isloading} dataCount={countDataTabs} callBackFunc={callBackFunc} refetchData={refetch}/>
        <AuthLayoutMode show={showLogin} setShow={setShowLogin}/>
        
        <InputDate
          className={clsx('form-control form-control-sm w-100 d-none',)}
          value={''}
          
          onChange={e=>{}}
          />
      {
        edit &&
        <ModalAddEditOrder createHospTrans={createHospTrans} refetchCurrent={refetch} show={edit} dataModal={dataModal} setDataModal={setDataModal} setShow={setEdit}/>
      }
        
        
    </>
}

export { PaymentTerminal };

